import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import "./index.scss";
import { FiAtSign, FiEdit3, FiPhone } from 'react-icons/fi';

function Perfil() {
    

    return ( 
        <>
            <Paper className="content-wrapper">

                <Grid container className="section-title column">
                    <h3>Meus Dados</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus turpis ac rhoncus posuere. !</p>
                </Grid>

                <form>
                 
                    <div className="form-wrapper center">
                        <label>Nome Completo</label>
                        <input/>
                    </div>

                    <div className="form-wrapper center">
                        <label>Email</label>
                        <div className="input-column prepend">
                            <FiAtSign />
                            <input/>
                        </div>
                    </div>

                    <div className="form-wrapper center">
                        <label>Celular</label>
                        <div className="input-column prepend">
                            <FiPhone />
                            <input/>
                        </div>
                    </div>

                </form>

                <Grid container className="page-actions" justify="flex-end">
                    <button className="btn green-bg">Salvar</button>
                </Grid>
                    
            </Paper>
        </>
    )
    
}

export default Perfil;