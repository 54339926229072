import React, { useEffect } from 'react';
import { useStateValue } from 'contexts';

import { useHistory } from "react-router-dom";

function Logout() {

    const [{}, dispatch] = useStateValue();
    const history = useHistory();

    useEffect(() => {
        logout();
    },[]);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    function logout(){
        localStorage.removeItem("@bsi");
        showFlash({type: "success", title: "Você foi deslogado!", caption: "Redirecionando..."});
        return history.push("/auth/signin");
    }

    return ( 
        <></>
    )
    
}

export default Logout;