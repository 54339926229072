import axios from "axios";
import Swal from 'sweetalert2';

// axios.defaults.baseURL = 'http://localhost:4000/api/v1';
axios.defaults.baseURL = 'https://sms-server.bsibr.com/api/v1';

function getToken(){

    try {
        const localData = JSON.parse(localStorage.getItem("@bsi"));
        const { auth: { token }} = localData;
        return token;
        
    } catch (error) {
        return null;
    }
}

axios.interceptors.response.use(
    request => {
        return request;
    },
    error => {

        const localData = JSON.parse(localStorage.getItem("@bsi"));

        if(localData && error.request.status === 401) {

            Swal.fire({
                title: 'Você foi deslogado',
                text: 'Seu token expirou você será redirecionado para tela de login',
                icon: 'warning',
                confirmButtonText: 'Entendi',
            }).then((result) => {
                if (result.value) {
                    localStorage.removeItem("@bsi");
                    window.location.reload();
                }

            });
            
        };
        
        return Promise.reject(error);

    }
);

axios.interceptors.request.use(
    request => {
        const jToken = getToken();

        if(!jToken) return request;

        request.headers.Authorization = jToken;
        
        return request;
    },
    error => {
        Promise.reject(error);
    }
);