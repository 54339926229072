import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import navs from "others/nav";
import logo from "assets/img/meridional.png";

const Footer = () => {

    return ( 
        <footer>
            <Grid container spacing={5}>
                
                <Grid item md>
                    <br />
                    <img src={logo} width="60%" />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed nibh erat. Sed at imperdiet purus. Nam ipsum mauris, imperdiet efficitur ligula eget, aliquam luctus erat.</p>
                </Grid>
                <Grid item md className="nav">
                    <h5>Navegue</h5>
                    <Link to="inicio">Inicio</Link>
                    { navs.map((value, index) => value.path && <Link to={value.path} key={index}>{value.title}</Link>) }
                    <Link to="minha-conta">Minha Conta</Link>
                </Grid>
                <Grid item md className="nav">
                    <h5>Contatos</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed nibh erat. Sed at imperdiet purus. Nam ipsum mauris, imperdiet efficitur ligula eget, aliquam luctus erat.</p>

                </Grid>
            </Grid>
        </footer>
    )
    
}

export default Footer;