import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FiChevronDown } from 'react-icons/fi';

function AccordionComponent({title, desc}){

    return(
        <Accordion style={{boxShadow: "none"}}>
            <AccordionSummary expandIcon={<FiChevronDown />} aria-controls="panel1a-content">
                <h3 style={{fontSize: 16, fontWeight: 500, margin: "10px 0", color: "#333"}}>{title}</h3>
            </AccordionSummary>
            <AccordionDetails>
                { desc }
            </AccordionDetails>
        </Accordion>
    )
    
}

export default AccordionComponent;