import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import "./index.scss";
import Alert from '@material-ui/lab/Alert';

function Senha() {
    

    return ( 
        <>
            <Paper className="content-wrapper">

                <Grid container className="section-title column">
                    <h3>Alteração de Senha</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus turpis ac rhoncus posuere. !</p>
                </Grid>
                
                <form style={{width: 500, marginLeft: "auto", marginRight: "auto"}}>

                    <div className="form-wrapper">
                        <Alert severity="error"> <b>Atenção!</b> Ao trocar a senha você será deslogado automaticamente!</Alert>
                    </div>

                    <div className="form-wrapper center">
                        <label>Senha atual</label>
                        <div className="input-column">
                            <input/>
                        </div>
                    </div>

                    <div className="form-wrapper center">
                        <label>Nova Senha</label>
                        <input/>
                    </div>

                    <div className="form-wrapper center">
                        <label>Repita a senha</label>
                        <input/>
                    </div>

                </form>

                <Grid container className="page-actions" justify="flex-end">
                    <button className="btn green-bg">Salvar</button>
                </Grid>
                    
            </Paper>
        </>
    )
    
}

export default Senha;