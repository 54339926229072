import React from 'react';

import "./index.scss";

function Dashboard() {
    
    return ( 
        <>
           <span>Dashboard</span>
        </>
    )
    
}

export default Dashboard;