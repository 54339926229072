import React, { useEffect, useState } from "react";
import { Grid, Chip } from "@material-ui/core";
import SmartTitle from "components/smartTitle";
import "./index.scss";
import axios from "axios";
import Dialog from "./dialog";

import { DataGrid } from "@material-ui/data-grid";
import { FiCheck, FiEdit2, FiEye, FiTrash } from "react-icons/fi";

function WebHooks() {
  const [webhooks, setWebhooks] = useState([]);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    listaWebHooks();
  }, [dialog]);

  async function listaWebHooks() {
    try {
      const request = await axios.get(`webhooks`);
      const { payload } = request.data;
      if (payload && payload.length) setWebhooks(payload);
    } catch (error) {}
  }

  async function removeWebHooks(id) {
    try {
      await axios.delete(`webhooks/${id}`);
    } catch (error) {
    } finally {
      listaWebHooks();
    }
  }

  const columns = [
    { field: "id", headerName: "#", width: 100 },
    { field: "url", headerName: "Url", width: 240 },
    { field: "headers", headerName: "Header", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.ativo ? "primary" : "secondary"}
          label={params.row.ativo ? "Ativo" : "Inativo"}
        />
      ),
    },
    { field: "created_at", headerName: "Data", width: 180 },
    {
      field: "action",
      headerName: "Ações",
      sortable: false,
      width: 240,
      renderCell: (params) => (
        <Grid container className="actions">
          <a className="edit" onClick={() => (params.row.open = true)}>
            <FiEdit2 /> Editar
          </a>
          <a className="delete" onClick={() => removeWebHooks(params.row.id)}>
            <FiTrash /> Excluir
          </a>

          {params.row.open && (
            <Dialog webhook={params.row} onClose={listaWebHooks} />
          )}
        </Grid>
      ),
    },
  ];

  return (
    <>
      {dialog && <Dialog onClose={() => setDialog(false)} />}

      <div className="webhooks wrapper">
        <Grid container className="header-content">
          <SmartTitle
            Action={() => (
              <a onClick={() => setDialog(true)} className="btn green-bg">
                Novo
              </a>
            )}
            title="Meus Hooks"
            count={webhooks.length}
            className="btn green-bg"
          />
        </Grid>

        <div className="main-content">
          <div className="custom-table">
            <DataGrid
              rows={webhooks}
              columns={columns}
              pageSize={5}
              disableColumnMenu={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebHooks;
