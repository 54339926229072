import { Grid } from '@material-ui/core';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import "./index.scss";

function SmartTitle({title, count, Action, onSearch}){

    return (
        <div className="smart-title">
            <Grid container alignItems="center" spacing={2}>

            <Grid item md={4} xs>
                <Grid container alignItems="center">
                    <h3> { title } </h3>
                    {
                        count ? <div className="total">{ count } Total</div> : ''
                    }
                    
                </Grid>
            </Grid>

            {
                Action && 
                <Grid item md xs={6} className="show-mobile">
                    <Grid container justify="flex-end">
                        { Action && <Action /> }
                    </Grid>
                </Grid>
            }
            

            <Grid item md={4} xs={12}>
                {
                    onSearch && 
                    <div className="form-wrapper center">
                        <div className="input-column prepend">
                            <FiSearch />
                            <input placeholder="Pesquisar..."/>
                        </div>
                    </div>
                }
            </Grid>

            <Grid item md className="hide-mobile">
                <Grid container justify="flex-end">
                    { Action && <Action /> }
                </Grid>
            </Grid>

            </Grid>
        </div>
    )
    
}

export default SmartTitle;