import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import Auth from "pages/auth";
import App from "pages/app";
import NoMatch from "pages/404";

import 'assets/scss/main.scss';
import 'typeface-roboto';

import { StateProvider } from 'contexts';

import reducers from 'contexts/reducer';
import initialState from 'contexts/initialState';

import Flash from "components/flash";
import { MuiThemeProvider } from '@material-ui/core';
import theme from "./theme";

require('./axios');

const Main = () => {

  document.title = "BSI";

  return (
    <MuiThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducers}>
        <Flash />
        <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Auth />
              </Route>
              <Route path="/auth">
                <Auth />
              </Route>
              <Route path="/app">
                <App />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
        </BrowserRouter>
      </StateProvider>
    </MuiThemeProvider>
  );
}

export default Main;