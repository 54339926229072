import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { useStateValue } from "contexts";

import Dashboard from "./dashboard";
import MinhaConta from "./minha-conta";
import Pacientes from "./pacientes";
import Notificacoes from "./mensagens";
import Webhooks from "./webhooks";
import Api from "./api";
import Header from "components/structure/header";
import Footer from "components/structure/footer";

function Private({ Component }) {
  return (
    <div className="app">
      <Header />

      <div className="content-wrapper">
        <Component />
      </div>

      <Footer />
    </div>
  );

  let history = useHistory();

  try {
    const localData = JSON.parse(localStorage.getItem("@bsi"));

    const {
      auth: { token, funcao_id },
    } = localData;

    if (!token) history.push("/auth/welcome");

    return <Component />;
  } catch (error) {
    setTimeout(() => {
      history.push("/auth/welcome");
    }, 500);

    return (
      <>
        <div className="app wrapper"></div>
      </>
    );
  }
}

function loadDefault(dispatch) {
  function loadUser() {
    const localData = JSON.parse(localStorage.getItem("@bsi"));

    if (!localData) return;

    dispatch({
      type: "setUser",
      payload: localData.user,
    });
  }

  loadUser();
}

const App = () => {
  let { path, isExact } = useRouteMatch();
  let history = useHistory();

  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    if (path === "/app" && isExact) return history.push("/app/dashboard");
  }, [path, isExact]);

  return (
    <div>
      <Switch>
        <Route path={`${path}/dashboard`}>
          <Private Component={Dashboard} />
        </Route>
        <Route path={`${path}/pacientes`}>
          <Private Component={Pacientes} />
        </Route>
        <Route path={`${path}/notificacoes`}>
          <Private Component={Notificacoes} />
        </Route>
        <Route path={`${path}/api`}>
          <Private Component={Api} />
        </Route>
        <Route path={`${path}/minha-conta`}>
          <Private Component={MinhaConta} />
        </Route>

        <Route path={`${path}/webhooks`}>
          <Private Component={Webhooks} />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
