import React, { useEffect, useState } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import SmartTitle from 'components/smartTitle'
import './index.scss'
import axios from 'axios'
import { FiDelete, FiSend } from 'react-icons/fi'
import { format } from 'date-fns'

import { DataGrid } from '@material-ui/data-grid'

function Patients() {
  const [pacientes, setPacientes] = useState([])

  useEffect(() => {
    listaPacientes()
  }, [])

  async function listaPacientes() {
    try {
      const request = await axios.get(`pacientes`)
      const { payload } = request.data

      if (payload && payload.length) setPacientes(payload)
    } catch (error) {}
  }

  const columns = [
    { field: 'id', headerName: '#', width: 100 },
    // { field: 'nome', headerName: 'Nome', width: 240 },
    { field: 'celular', headerName: 'Celular', width: 160 },
    { field: 'created_at', headerName: 'Data', width: 220, renderCell: ({ row }) => format(new Date(row.created_at), "dd/MM/yyyy HH:mm:ss") },
    {
      field: 'action',
      headerName: 'Mensagens',
      sortable: false,
      width: 180,
      renderCell: ({ row }) => {
        const successMsgs = row.Mensagens.filter((value) => value.status === 'Recebida')
        const errMsgs = row.Mensagens.filter((value) => value.status === 'Error')

        return (
          <Grid container className='actions'>
            <Tooltip title='Mensagens enviadas'>
              <a className='edit'>
                <FiSend /> {successMsgs.length}
              </a>
            </Tooltip>
            <Tooltip title='Mensagens com error'>
              <a className='delete'>
                <FiDelete /> {errMsgs.length}
              </a>
            </Tooltip>
          </Grid>
        )
      },
    },
  ]

  return (
    <>
      <div className='patients wrapper'>
        <Grid container className='header-content'>
          <SmartTitle title='Meus Pacientes' onSearch={(query) => console.log(query)} count={pacientes.length} className='btn green-bg' />
        </Grid>

        <div className='main-content'>
          <div className='custom-table'>
            <DataGrid rows={pacientes} columns={columns} pageSize={10} disableColumnMenu={true} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Patients
