import React, { useState } from 'react'
import './index.scss'
import { CircularProgress, Grid } from '@material-ui/core'
import { FiCopy, FiRefreshCcw } from 'react-icons/fi'
import Accordion from 'components/faq/accordion'
import axios from 'axios'
import { useEffect } from 'react'

const navs = [
  {
    title: 'Primeiros Passos',
    questions: [
      {
        title: '',
        desc: '',
      },
      {
        title: '',
        desc: '',
      },
    ],
  },
]

const Documentation = () => {
  const [active, setActive] = useState(0)
  const [token, setToken] = useState('')
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    getCurrentToken()
  }, [])

  async function createToken() {
    try {
      setLoading(true)
      const request = await axios.post(`tokens`)
      const { payload } = request.data
      setToken(payload.id)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getCurrentToken() {
    try {
      const request = await axios.get(`token`)
      const { payload } = request.data

      setToken(payload.id)

      console.log(payload)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const DisplayToken = () => {
    if (isLoading)
      return (
        <Grid container justify='center'>
          <CircularProgress size={24} />
        </Grid>
      )

    return (
      <>
        <div className='form-wrapper center'>
          <div className='input-column prepend'>
            <FiRefreshCcw onClick={createToken} />
            <input placeholder='Clique para gerar uma chave' disabled value={token} />
            <FiCopy className='end-icon' />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='faq wrapper'>
      <Grid container className='header-content' alignItems='center' justify='center'>
        <Grid item md={5} xs={12}>
          <h2>Token de acesso</h2>
          <DisplayToken />
        </Grid>
      </Grid>

      <div className='main-content'>
        <Grid container spacing={5}>
          <Grid item md={3} className='page-subnav'>
            {navs.map((value, index) => (
              <a key={index} onClick={() => setActive(index)} className={`${index === active && 'active'}`}>
                {value.icon} {value.title}
              </a>
            ))}
            <a onClick={() => setActive(10)} className={`${10 === active && 'active'}`}>
              Enviar SMS
            </a>

            <a disabled className={`${11 === active && 'active'}`}>
              Pacientes <small>(Indisponivel)</small>
            </a>

            <a disabled className={`${11 === active && 'active'}`}>
              Mensagens <small>(Indisponivel)</small>
            </a>

            <a disabled className={`${11 === active && 'active'}`}>
              Webhooks <small>(Indisponivel)</small>
            </a>
          </Grid>

          <Grid item md style={{ padding: 20 }}>
            {active === 0 && (
              <>
                <div className=''>
                  <h3>Introdução e Visão Geral</h3>
                  <p>
                    Bem vindo(a)!  <br /> Com a nossa api você pode fazer Envio de SMS para todas as operadoras de forma simples e prática. <br /> Este documento descreve as características técnicas da API para integração, visando permitir o
                    desenvolvimento da comunicação entre as plataformas envolvidas.
                  </p>
                  <br />
                  <h3>Definição do Protocolo - REST</h3>
                  <p>
                    A sigla REST, em português, significa “Transferência de Estado Representacional”. Concebido como uma abstração da arquitetura da web, trata-se de um conjunto de princípios e definições necessários para a criação de um
                    projeto com interfaces bem definidas.
                  </p>
                </div>
              </>
            )}

            {active === 10 && (
              <>
                <div className=''>
                  <h3>Envio SMS (MT)</h3>
                  <p>
                    Endpoint: <code>https://sms-server.bsibr.com/api/v1/mensagens</code>
                  </p>
                  <p>
                    Método: <code>POST</code>
                  </p>
                  <p>Descrição: Envio de SMS podendo ser enviada 1 (uma) ou N mensagem(ns) por requisição. No retorno já é disponibilizado o id único da mensagem na SMS Dev. Ele pode ser utilizado posteriormente para consultas.</p>


                  <h3>Autenticação nas rotas</h3>
                  <p>O token pode ser recriado a qualquer momento clicando no refresh acima</p>
                  <p>É necessário passar no header da requisição.</p>

                  <p>Exemplo:</p>
                  <code>Authorization : { token }</code>

                  <table>
                    <thead>
                      <tr>
                        <th>NOME</th>
                        <th>TIPO</th>
                        <th>OBRIGATÓRIO</th>
                        <th>DESCRIÇÃO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <code>celular</code>
                        </td>
                        <td>texto</td>
                        <td>Sim</td>
                        <td>
                          Número destinatário <br /> Ex: 5511988887777 ou 11988887777.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <code>msg</code>
                        </td>
                        <td>texto</td>
                        <td>Sim</td>
                        <td>Até 160 caracteres</td>
                      </tr>

                      <tr>
                        <td>
                          <code>matricula</code>
                        </td>
                        <td>texto</td>
                        <td>Não</td>
                        <td>Até 20 caracteres</td>
                      </tr>

                      <tr>
                        <td>
                          <code>nome</code>
                        </td>
                        <td>texto</td>
                        <td>Não</td>
                        <td>Até 200 caracteres</td>
                      </tr>
                    </tbody>
                  </table>

                  <Grid container spacing={7}>
                    <Grid item>
                      <h3>Exemplo simples:</h3>

                      <pre style={{ padding: 10 }}>{JSON.stringify({ matricula: 122213, celular: '27996405494', msg: 'Mensagem de texto', nome: 'Lucas Costa' }, null, 2)}</pre>
                    </Grid>

                    <Grid item>
                      <h3>Envio em lote:</h3>
                      <pre style={{ padding: 10, width: 300 }}>
                        {JSON.stringify(
                          [
                            { celular: '27996405494', msg: 'Mensagem de texto' },
                            { celular: '27996405494', msg: 'Mensagem de texto' },
                          ],
                          null,
                          2
                        )}
                      </pre>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Documentation
