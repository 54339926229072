import React from 'react'
import { Chip, Grid } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import SmartTitle from 'components/smartTitle'

import './index.scss'
import { FiCheck, FiEye } from 'react-icons/fi'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { format } from 'date-fns'

function Mensagens() {
  const [mensanges, setMensagens] = useState([])

  useEffect(() => {
    listaPacientes()
  }, [])

  async function listaPacientes() {
    try {
      const request = await axios.get(`mensagens`)
      const { payload } = request.data

      if (payload && payload.length) setMensagens(payload)
    } catch (error) {}
  }

  const columns = [
    { field: 'id', headerName: '#', width: 80 },
    // { field: 'paciente', headerName: 'Paciente', width: 140, renderCell: ({ row }) => row.Paciente.nome },
    { field: 'celular', headerName: 'Celular', width: 140, renderCell: ({ row }) => row.Paciente.celular },
    { field: 'msg', headerName: 'Mensagem', width: 320 },
    { field: 'created_at', headerName: 'Data', width: 220, renderCell: ({ row }) => format(new Date(row.created_at), "dd/MM/yyyy HH:mm:ss") },
    { field: 'status', headerName: 'Status', width: 140, renderCell: (params) => <Chip size='small' deleteIcon={<FiCheck />} color='primary' label={params.row.status} /> },
    // {
    //     field: 'action',
    //     headerName: 'Ações',
    //     sortable: false,
    //     width: 120,
    //     renderCell: (params) =>
    //     <Grid container className="actions">
    //             <a className="edit"><FiEye /> Abrir</a>
    //     </Grid>
    // },
  ]
  const localeTranslate = {
    footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  }
  return (
    <>
      <div className='services wrapper'>
        <Grid container className='header-content'>
          <SmartTitle title='Notificações por SMS' count='2' />
        </Grid>

        <div className='main-content'>
          <Grid container spacing={5}>
            <div className='custom-table'>
              <DataGrid rows={mensanges} columns={columns} pageSize={10} disableColumnMenu={true} localeText={localeTranslate} />
            </div>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Mensagens
