import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button, TextField } from '@material-ui/core'; 
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import { useStateValue } from 'contexts';
import meridional from "assets/img/meridional.png";

import { useHistory } from "react-router-dom";
     
import "./index.scss";

function Signin() {

    const [{}, dispatch] = useStateValue();

    useEffect(() => {
      localStorage.removeItem("@bsi");
    },[]);

    let history = useHistory();

    const [credential, setCredential] = useState({});

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    async function doLogin(evt){

        evt.preventDefault();

        const { email, senha } = credential;

        if(!email || !senha) return showFlash({type: "error", title: "Ocorreu um erro ao autenticar!", subTitle: "Preencha corretamente os campos!"});

        try {
            const request = await axios.post("/public/auth/signin", { email, senha } );

            const response = request.data;

            const nome = response.payload.nome;
            
            showFlash({type: "success", title: `Olá, ${nome}`, subTitle: "A conexão segura estabelecida", caption: "Você será redirecionado..."});
            
            const temp = { auth: { token: response.token, funcao_id: response.payload.funcao_id }, user: response.payload };
            
            localStorage.setItem("@bsi", JSON.stringify(temp));

            setTimeout(() => {
                history.push("/app/notificacoes");
            }, 2000);

        } catch (error) {

            if(!error.response || !error.response.data) return showFlash({type: "error", title: "Ocorreu um erro ao autenticar!", subTitle: "O servidor encontra-se offline, tente novamente mais tarde."});

            if(error.response.data.msg) return showFlash({type: "error", title: "Ocorreu um erro ao autenticar!", subTitle: error.response.data.msg});
            
        }
     
    }
    
    return ( 
        <>
           <Grid className="wrapper-full auth signin" container justify="center" alignItems="center">
                <Grid item md={5} xs={12}>
                    <Paper square>
                        <div className="login-area">
                            <img alt="Meridional Logo" src={meridional} className="logo" />
                            <form onSubmit={doLogin}>
                                <TextField onChange={(evt) => setCredential({...credential, email : evt.target.value})} color="primary" className="custom-input" label="Email" fullWidth variant="outlined" />
                                <TextField type="password" onChange={(evt) => setCredential({...credential, senha: evt.target.value})} className="custom-input" label="Senha" fullWidth variant="outlined" />
                                <p>Esqueceu a senha? <a href="#/forgot"><b>Clique aqui</b></a></p>
                                <br />
                                <button type="submit" className="btn green-bg">
                                    Entrar
                                </button>
                                <button type="submit" className="btn green-bg outline">
                                    Criar Conta
                                </button>
                            </form>
                        </div>
                    </Paper>
                </Grid>
                <Grid item md xs={12}></Grid>

           </Grid>
           <small style={{position: "absolute", bottom: 10, left: 20, color: "white"}}>Versão 0.13</small>
        </>
    )
    
}

export default Signin;