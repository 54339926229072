import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import "./index.scss";
import { FiLock, FiPower, FiUser } from 'react-icons/fi';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Perfil from "./perfil";
import Senha from "./senha";

function Conta() {

    const [active, setActive] = useState(0);

    const { path } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        history.push("/app/minha-conta/perfil");
    }, []);

    const navs = [
        { title: "Perfil", path: "/app/minha-conta/perfil", icon: <FiUser /> },
        { title: "Mudar Senha", path: "/app/minha-conta/trocar-senha", icon: <FiLock /> },
        { title: "Sair", path: "/auth/logout", icon: <FiPower /> },

    ]

    function getActivePath(navPath){
        const currentPath = window.location.href;
        return currentPath.includes(navPath);
    }

    return ( 
        <>
            <div className="page-content minha-conta">

                <div className="section-title">
                  <h3>Minha Conta</h3>
                </div>

                <Grid container spacing={5}>
                    <Grid item md={3} xs={12} className="page-subnav">
                        {
                            navs.map((value, index) => <Link key={index} to={value.path} onClick={() => setActive(index)} className={`${getActivePath(value.path) && 'active'}`}>{value.icon} {value.title}</Link>)
                        }
                    </Grid>
                    <Grid item md>
                    
                        <Switch>

                            <Route exact path={`${path}/perfil`}>
                                <Perfil />
                            </Route>

                            <Route exact path={`${path}/trocar-senha`}>
                                <Senha />
                            </Route>

                        </Switch>

                    </Grid>
                </Grid>
            </div>
        </>
    )
    
}

export default Conta;