import { Container, Grid } from '@material-ui/core';
import React from 'react';
import defaultImage from "assets/img/404.svg";
import "./index.scss";


const Auth = () => {

    return (
        <div className="error-404">

            <Container>
                <Grid container alignItems="center">
                    <Grid md={5}>
                        <h1>404</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed nibh erat. Sed at imperdiet purus. Nam ipsum mauris, imperdiet efficitur ligula eget, aliquam luctus erat.</p>
                    </Grid>
                    <Grid md className="hide-mobile">
                        <img src={defaultImage} />
                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}

export default Auth;