import React, { useState } from 'react';
import { FiPower } from "react-icons/fi";
import navs from "others/nav";
import logo from "assets/img/meridional-m-white.png";
import { slide as Menu } from 'react-burger-menu';
import meridional from "assets/img/meridional.png";

import "./index.scss";
import { Link, useHistory, useLocation } from 'react-router-dom';

import * as FeatherIcon from "react-icons/fi";
import * as FontAwesome from "react-icons/fa";

import { Grid, Tooltip } from '@material-ui/core';

const Header = () => {

    const [mobileNavOpen, setMobileOpen] = useState(false);

    const { pathname } = useLocation();
    const history = useHistory();

    const matchCheck = (route) => {
        return pathname.includes(route);
    }

    function logout(){
        localStorage.removeItem("@bsi");
        return history.push("/auth/welcome");
    }

    function navigate(path){
        if(mobileNavOpen) setMobileOpen(false);
        history.push(path);
    }
    
    return ( 
        <header>
            
            <div className="desktop-nav">

                <div className="header-nav">
                    <Tooltip title="Principal" aria-label="principal" placement="right" arrow>
                        <Link to={`/app/dashboard`} className="logo"><img src={logo} width={42} /></Link>
                    </Tooltip>
                </div>

                <div className="center-nav">
                    
                    {
                        navs.map((value, index) => {
                            
                            const icon = value.icon.includes("Fi") ? React.createElement(FeatherIcon[value.icon]) : React.createElement(FontAwesome[value.icon]);
                            
                            return (
                                <Tooltip key={index} title={value.title} aria-label={value.title} placement="right" arrow>
                                    <Link className={ matchCheck(value.path) ? "active" : ''} to={value.path}>
                                        <span>{icon}</span>
                                    </Link>
                                </Tooltip>
                            )

                        })
                    }

                </div>

                <div className="footer-nav">
                    {/* <Tooltip title="Minha Conta" aria-label="Minha Conta" placement="right" arrow>
                        <Link className={ matchCheck(`/app/minha-conta`) ? "active" : ''} to={`/app/minha-conta`}>
                            <span><FiUser /></span>
                        </Link>
                    </Tooltip> */}
                    <Tooltip title="Minha Conta" aria-label="Minha Conta" placement="right" arrow>
                        <Link to={`/auth/logout`}>
                            <span><FiPower /></span>
                        </Link>
                    </Tooltip>
                </div>
                
            </div>

            <Grid container alignItems="center" className="mobile-nav">
                
                <div className={`hamburger${mobileNavOpen ? ' opened' : ''}`}>
                <Menu isOpen={ mobileNavOpen } onStateChange={({isOpen}) => setMobileOpen(isOpen)} right customBurgerIcon={ <FeatherIcon.FiMenu size={40}/> } customCrossIcon={ <FeatherIcon.FiX size={40} /> }>
                    <img className="logo" src={meridional} width="180px" alt="Meridional logo"/>
                    { navs.map((value, i) => <span key={i} className={ matchCheck(value.path) ? "active" : ''} onClick={() => navigate(value.path)}>{value.title}</span>) }
                    <span onClick={logout}>Sair</span>
                </Menu>
                </div>
            </Grid>
            
        </header>
    )
    
}

export default Header;