import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import Signin from "./signin";
import Logout from "./logout";

const Auth = () => {

    const { path, isExact } = useRouteMatch();
    const history = useHistory();
  
    useEffect(() => {
        if(path === '/' && isExact) history.push("auth/signin");
    },[path, isExact, history]);

    return ( 
        <div className="background-pattern">
            <Switch>
                <Route exact path={`${path}/signin`}>
                    <Signin />
                </Route>
                <Route exact path={`${path}/logout`}>
                    <Logout />
                </Route>
            </Switch>
        </div>
    )
    
}

export default Auth;