import React, { useEffect, useState } from "react";
import { Dialog, Grid, Switch } from "@material-ui/core";
import "./index.scss";
import axios from "axios";

function WebHookDialog({webhook, onClose}){

    const [ edit, setEdit ] = useState({
        url: '',
        ativo: true,
        headers: ''
    });

    useEffect(() => {
        if(webhook) setEdit(webhook);
    }, [webhook]);

    async function save() {
        
        try {
            (edit.id) ? await axios.put(`webhooks/${edit.id}`, edit) : await axios.post(`webhooks`, edit);
        } catch (error) {
        } 
        finally{
            onClose();
        }
      }

    return (
        <Dialog open={true} onClose={() => onClose() } id="dialog-square" maxWidth="md">

            <div className="webhooks dialog">

                <Grid container className="section-title column">
                    <h3>Cadastro de webhook</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus turpis!</p>
                </Grid>

                <Grid container spacing={4}>

                    <Grid item md xs>

                        <div className="form-wrapper center column">
                            <label>Url</label>
                            <div className="input-column">
                                <input defaultValue={edit.url} onChange={(evt) => setEdit({...edit, url: evt.target.value }) } />
                            </div>
                        </div>

                    </Grid>

                    <Grid item md={2} xs>
                        <div className="form-wrapper center column">
                            <label>ativo</label>
                            <div className="input-column prepend">
                                <Switch
                                    checked={edit.ativo}
                                    color="primary"
                                    onClick={(evt) => setEdit({...edit, ativo: !edit.ativo }) }
                                />
                            </div>
                        </div>
                    </Grid>

                </Grid>

                <Grid container spacing={4}>

                    <Grid item md={12} xs>
                        <div className="form-wrapper center column">
                            <label>Header Authorization Basic</label>
                            <div className="input-column">
                                <input defaultValue={edit.headers} onChange={(evt) => setEdit({...edit, headers: evt.target.value }) } placeholder="authorization:token" />
                            </div>
                        </div>

                    </Grid>

                </Grid>
                
                <Grid container className="actions" justify="flex-end">
                    <button className="btn gray-bg" onClick={onClose}>Cancelar</button>
                    <button className="btn green-bg" onClick={save} disabled={!edit.url}>Salvar</button>
                </Grid>
               

            </div>

        </Dialog>
    )
    
}

export default WebHookDialog;